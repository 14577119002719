/* eslint-disable max-len */
module.exports = {
  index: {
    desc: 'En oversikt over alle templatene',
    table: {
      name: {
        missing: 'Mangler navnet på templatet'
      },
      customer: {
        all: 'Alle kundene',
        missing: 'Mangler navnet på kunden'
      },
      btn: {
        title: 'Last inn flere templater',
        loading: 'Laster inn templater...'
      }
    }
  },
  edit: {
    loading: 'Laster inn templatdata',
    btns: {
      dashboard: 'Oversikt',
      styletypes: 'Stiltyper',
      designer: 'Designer',
      settings: 'Innstillinger'
    },
    info: {
      missingName: 'Mangler templatnavnet',
      missingVarycode: 'Mangler Varycoden',
      missingImage: 'Mangler templatbilde',
      missingDesc: 'Mangler templatinfo',
      image: {
        change: {
          success: {
            desc: 'Templatbildet ble oppdatert'
          },
          error: {
            desc: 'Det oppstod en feil under oppdatering av bilder: :err'
          }
        }
      },
      countcard: {
        title: 'Statistikk',
        totalCount: 'Produkter totalt'
      }
    },
    styletypes: {
      list: {
        title: 'Stiltyper',
        desc: 'Tilgjengelige stiltyper for dette templatet',
        empty: 'Fant ingen stiltyper for dette templatet',
        btn: 'Lag ny stiltype'
      },
      add: {
        title: 'Lag ny stiltype',
        btn: {
          default: 'Lag ny stiltype',
          loading: 'Lager ny stiltype...'
        },
        form: {
          fields: {
            name: {
              title: 'Navn',
              placeholder: 'Skriv inn stilnavnet',
              errors: {
                alpha: 'Stilnavnet kan kun bestå av tall, bokstaver og bindestrek',
                required: 'Stilnavnet må være lenger enn 2 bokstaver'
              }
            },
            desc: {
              title: 'Beskrivelse',
              placeholder: 'Skriv en kort beskrivelse av stilen',
              error: 'En kort beskrivelse kreves'
            },
            position: {
              title: 'Posisjon',
              placeholder: 'Skriv inn et tall for å angi posisjonen',
              errors: {
                alpha: 'Posisjonen kan kun bestå av tall'
              }
            }
          }
        },
        toast: {
          success: {
            title: 'Ny stiltype opprettet',
            msg: 'Stiltypen ble lagret til databasen'
          },
          error: {
            title: 'En feil oppstod',
            msg: 'Den nye stiltypen kunne ikke opprettes: :error'
          }
        }
      },
      edit: {
        title: 'Editer :styletypename',
        form: {
          fields: {
            standard: {
              title: 'Skal stilen være standard?'
            }
          }
        },
        btn: {
          loading: 'Lagrer stiltypen',
          default: 'Lagre endringer'
        }
      },
      delete: {
        title: 'Slett stiltypen',
        desc: 'Er du sikker på at du vil slette :styletypename',
        missingName: '(mangler stiltypenavn)',
        btn: {
          loading: 'Sletter stiltypen',
          default: 'Slett stiltypen'
        },
        toast: {
          error: {
            title: 'Feil under sletting av stiltype',
            msg: 'Kunne ikke slette stiltypen'
          },
          success: {
            title: 'Stiltypen ble slettet',
            msg: 'Stiltypen ble slettet fra databasen'
          }
        }
      }
    },
    designer: {
      count: {
        totals: {
          unknown: 'Ukjent'
        }
      },
      backgroundImage: {
        title: 'Bakgrunnsbilde',
        desc: 'Bakgrunnsbildet til designeren'
      },
      products: {
        title: 'Produkter',
        desc: 'Alle produktene i dette templatet',
        layer: {
          title: 'Lag',
          inputMissing: '?',
          update: {
            error: {
              title: 'Kunne ikke oppdatere produktet',
              msg: 'En feil oppstod: :error'
            },
            success: {
              title: 'Produktet ble oppdatert!',
              msg: 'Laget ble endret.'
            }
          }
        },
        btn: {
          default: 'Last inn flere produkter'
        },
        table: {
          name: {
            missing: 'Mangler produktnavn'
          },
          desc: {
            missing: 'Mangler produktbeskrivelse'
          },
          render: {
            missing: 'Mangler render'
          }
        }
      }
    }
  },
  modals: {
    add: {
      title: 'Lag nytt templat',
      btn: {
        default: 'Lag nytt templat',
        loading: 'Lager nytt templat...'
      },
      form: {
        fields: {
          name: {
            title: 'Navn',
            placeholder: 'Skriv inn templatnavnet',
            errors: {
              alpha: 'Templatnavnet kan kun bestå av tall, bokstaver og bindestrek',
              required: 'Templatnavnet må være lenger enn 2 bokstaver'
            }
          },
          desc: {
            title: 'Beskrivelse',
            placeholder: 'Skriv en kort beskrivelse av templatet',
            error: 'En kort beskrivelse kreves'
          },
          customer: {
            title: 'Tilgjengelig for',
            missing: 'Mangler kundenavn...',
            everybody: 'For alle',
            loadingCustomers: 'Henter kunder...'
          }
        }
      },
      toast: {
        success: {
          title: 'Nytt templat opprettet',
          msg: 'Templatet ble lagret til databasen'
        },
        error: {
          title: 'En feil oppstod',
          msg: 'Det nye templatet kunne ikke opprettes: :error'
        }
      }
    },
    delete: {
      title: 'Slette templat',
      desc: 'Vil du virkelig slette <b>:templatename</b>?<br><br>Dette vil også slette:<br>-Alle stiltypene<br>-Alle oppgavene relatert til stiltyper<br>-Alle oppgaver relatert til produktene i designeren',
      missingName: '(mangler templatnavnet)',
      btn: {
        loading: 'Sletter templatet',
        default: 'Slett templatet'
      },
      toast: {
        success: {
          title: 'Templatet ble slettet',
          msg: 'Templatet ble fjernet fra databasen'
        },
        error: {
          title: 'Templatet ble ikke slettet',
          msg: 'Det oppstod en feil under sletting av templatet: :error'
        }
      }
    }
  }
}
