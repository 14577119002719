/* eslint-disable max-len */
// logs.products.sync.main
module.exports = {
  index: {
    title: 'Systemlogg',
    desc: 'En oversikt over hendelser i systemet',
    loading: 'Laster inn systemlogger',
    btn: {
      loadMore: 'Last inn flere logger'
    },
    items: {
      labels: {
        missing: {
          title: 'Ukjent (mangler loggtittel)',
          desc: '(Mangler beskrivelse av loggen)'
        },
        newtasknewrender: {
          title: 'Ny oppgave: Ny render',
          desc: 'Produktet :productname ble lagt til templatet :templatename og trenger en render',
          btn: 'Gå til templatet'
        },
        completedtasknewrender: {
          title: 'Oppgave løst: Ny render',
          desc: ':authuser lastet opp en ny render til produktet :productname i templatet :templatename',
          btn: 'Gå til designeren',
          unknownTemplate: '(ukjent templatnavn)',
          unknownUser: '(ukjent bruker)',
          unknownProduct: '(ukjent produkt)'
        },
        customersync: {
          title: 'Kundesynkronisering',
          desc: ':count kunder ble synkronisert via API',
          unknown: '(mangler antall kunder)',
          btn: 'Se detaljer'
        },
        templatecreation: {
          title: 'Nytt templat',
          desc: ':templatename ble opprettet av :authuser',
          unknown: '(mangler navnet)',
          btn: 'Gå til templatet'
        },
        productsync: {
          title: 'Produktsynkronisering',
          desc: ':count produkter ble synkronisert via API',
          unknown: '(mangler antall)',
          btn: 'Se detaljer'
        },
        userlogin: {
          title: 'Brukerlogin',
          desc: ':username logget inn',
          unknown: 'En ukjent bruker logget inn'
        },
        newtasknewtemplateimage: {
          title: 'Ny oppgave: Templat trenger visningsbilde',
          desc: 'Templatet :templatename ble opprettet og trenger et bilde',
          btn: 'Gå til templatet',
          unknown: '(ukjent templatnavn)'
        },
        completedtasktemplateimage: {
          title: 'Oppgave løst: Templat trenger visningsbilde',
          desc: ':user lastet opp et bilde til :templatename',
          unknownTemplate: '(ukjent templatnavn)',
          unknownUser: '(ukjent bruker)'
        },
        newtasknewstyletypeimage: {
          title: 'Ny oppgave: Stiltype trenger visningsbilde',
          desc: 'Templatet :templatename fikk en ny stiltype :styletypename',
          btn: 'Gå til templatets stiltyper',
          unknownTemplate: '(ukjent templatnavn)',
          unknownStyletype: '(ukjent stiltypenavn)'
        },
        completedtaskstyletypeimage: {
          title: 'Oppgave løst: Stiltype trenger visningsbilde',
          desc: ':user lastet opp et bilde til :styletypename i templatet :templatename',
          unknownTemplate: '(ukjent templatnavn)',
          unknownUser: '(ukjent bruker)'
        },
        newtasknewtemplatedesignerbg: {
          title: 'Ny oppgave: Designer trenger bakgrunnsbilde',
          desc: 'Designeren i templatet :templatename trenger et bakgrunnsbilde',
          btn: 'Gå til designeren',
          unknownTemplate: '(ukjent templatnavn)'
        },
        completedtasknewtemplatedesignerbg: {
          title: 'Oppgave løst: Designer trenger bakgrunnsbilde',
          desc: ':authuser lastet opp et bilde til designeren i templatet :templatename',
          unknownTemplate: '(ukjent templatnavn)',
          unknownUser: '(ukjent bruker)',
          btn: 'Gå til designeren'
        },
        deletedtaskstyletypeimage: {
          title: 'Oppgave ble slettet: Stiltype trenger visningsbilde',
          desc: 'Stiltypen :styletypename ble slettet fra templatet :templatename',
          unknownTemplate: '(ukjent templatnavn)',
          unknownStyletype: '(ukjent stiltypenavn)'
        },
        styletypecreation: {
          title: 'Ny stiltype',
          desc: ':styletypename ble opprettet av :authuser i :templatename',
          unknown: '(mangler navnet)',
          btn: 'Gå til templatet'
        },
        styletypedeleted: {
          title: 'Slettet stiltype',
          desc: ':styletypename ble slettet av :authuser i :templatename',
          unknown: '(mangler navnet)',
          btn: 'Gå til templatet'
        },
        styletypeupdated: {
          title: 'Oppdaterte stiltype',
          desc: ':styletypename ble oppdatert av :authuser i :templatename',
          unknown: '(mangler navnet)',
          btn: 'Gå til templatet'
        },
        templatedeleted: {
          title: 'Templat ble slettet',
          desc: ':templatename ble slettet av :authuser',
          unknownTemplate: '(mangler templatdata)',
          unknownUser: '(mangler brukerdata)'
        },
        deletedtemplateimage: {
          title: 'Oppgave ble slettet: Templat trenger bilde',
          desc: 'Templatet :templatename ble slettet og trenger ikke lenger et bilde',
          unknownTemplate: '(ukjent templatnavn)'
        },
        deletedstyletypeimage: {
          title: 'Oppgave ble slettet: Stiltype trenger visningsbilde',
          desc: 'Stilen :styletypename som trengte visningsbilde var i templatet :templatename som ble slettet.',
          unknownTemplate: '(ukjent templatnavn)',
          unknownStyletype: '(ukjent stiltypenavn)'
        },
        taskskipped: {
          title: 'Oppgave ble hoppet over',
          desc: ':authuser hoppet over oppgaven: <b>:taskname</b>',
          unknownTask: '(ukjent templatnavn)',
          unknownUser: '(ukjent bruker)'
        }
      }
    }
  },
  products: {
    sync: {
      title: 'Synkroniseringslogg',
      main: {
        title: 'Produktsynkronisering ble utført',
        desc: ':total nye produkter ble oppdaget'
      },
      item: {
        start: 'Synkroniseringen ble startet',
        end: 'Synkroniseringen ble avsluttet'
      },
      new: {
        title: 'Nytt produkt',
        desc: ':name ble lagt til i databasen'
      },
      updated: {
        title: 'Produkt ble oppdatert',
        desc: ':name ble oppdatert'
      },
      error: {
        title: 'En feil oppstod',
        desc: 'Det oppstod en feil når produktet skulle synkroniseres: :err'
      },
      errorupdated: {
        title: 'En feil oppstod',
        desc: 'Det oppstod en feil når produktet skulle synkroniseres: :err'
      }
    }
  },
  templates: {
    create: {
      title: 'Et nytt templat ble opprettet '
    },
    deleted: {
      title: 'Detaljert slettelogg',
      loading: 'Laster inn loggdata',
      details: {
        title: 'Log ID: :logId',
        desc: 'En oversikt over hendelsene som ble utført i forbindelse med sletting av templatet'
      },
      list: {
        title: 'Detaljert oversikt',
        desc: 'En detaljert liste over hendelsene som ble utført'
      }
    }
  },
  customers: {
    sync: {
      title: 'Synkroniseringslogg'
    }
  },
  tasks: {
    master: {
      new: 'Et nytt gjøremål ble opprettet'
    }
  },
  users: {
    login: {
      title: ''
    }
  }
}
