/* eslint-disable max-len */
module.exports = {
  index: {
    desc: 'En oversikt over alle kundene hos SG som har tilgang til templates',
    list: {
      fields: {
        extid: 'Ekstern ID',
        id: 'Intern ID',
        createdOn: 'Opprettet',
        status: 'Status'
      },
      btn: {
        loadMore: 'Last inn flere kunder'
      },
      status: {
        loading: 'Laster inn'
      }
    }
  },
  sync: {
    desc: 'Synkroniser kundene i SG som har tilgang til templater',
    start: 'Start synkronisering',
    running: 'Synkroniserer...',
    started: 'Startet',
    ended: 'Fullført',
    errors: {
      title: 'Det oppstod feil under synkroniseringen!',
      msg: 'Feilmelding fra serveren: :errors'
    },
    progress: 'Progresjon',
    active: 'Synk aktiv...',
    total: 'Totalt',
    processed: 'Fullført',
    unchanged: 'Uendret',
    new: 'Nye',
    failed: 'Feilet',
    deleted: 'Slettede',
    restored: 'Gjenopprettede',
    log: {
      title: 'Synkroniseringslogg',
      desc: 'En oversikt over tidligere kundesynkroniseringer',
      btn: {
        loadMore: 'Hent flere synkroniseringer'
      }
    },
    item: {
      start: {
        title: 'Synkroniseringen startet',
        desc: 'Kundesynkroniseringen ble startet',
        status: 'Startet'
      },
      new: {
        title: 'Ny bruker',
        desc: '',
        status: 'Ny bruker'
      },
      error: {
        title: 'Feil oppstod',
        desc: '',
        status: 'Feil'
      },
      restored: {
        title: 'Brukeren ble gjenopprettet',
        desc: '',
        status: 'Gjenopprettet'
      },
      deleted: {
        title: 'Brukeren ble slettet',
        desc: '',
        status: 'Slettet'
      },
      updateFailed: {
        title: 'Det oppstod en feil under oppdatering av brukeren',
        desc: '',
        status: 'Feil'
      },
      unchanged: {
        title: 'Uendret',
        desc: '',
        status: 'Uendret'
      },
      end: {
        title: 'Synkroniseringen avsluttet',
        desc: '',
        status: 'Avsluttet'
      }
    }
  }
}
