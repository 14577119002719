/* eslint-disable max-len */
module.exports = {
  cards: {
    stats: {
      title: 'Statistikk',
      list: {
        backendUsers: 'Brukere'
      }
    }
  }
}
