/* eslint-disable max-len */
module.exports = {
  aside: {
    templates: {
      all: 'Alle templatene',
      add: 'Lag nytt templat',
      title: 'Templater'
    },
    products: {
      all: 'Alle produktene',
      title: 'Produkter',
      sync: 'Synkroniser produkter'
    },
    customers: {
      all: 'Alle kundene',
      title: 'Kunder',
      sync: 'Synkroniser kunder'
    },
    sessions: {
      all: 'Alle økter',
      title: 'Økter'
    }
  }
}
