/* eslint-disable max-len */
module.exports = {
  index: {
    title: 'Oppgaver',
    desc: 'Liste over oppgaver som må gjøres',
    btn: {
      loadMore: 'Last inn flere oppgaver',
      loading: 'Henter oppgaver...',
      skipTask: 'Hopp over'
    },
    toolbar: {
      pending: 'Aktive',
      all: 'Alle'
    },
    list: {
      missing: {
        title: '(mangler tittel)',
        desc: '(mangler beskrivelse)',
        by: '(mangler brukernavn)'
      },
      newrender: {
        title: 'Produkt trenger render',
        desc: ':productname mangler render i templatet :templatename',
        btn: 'Utfør oppgave',
        unknownTemplate: 'ukjent templat, kan ha blitt slettet',
        completedBy: 'Fullført av :username'
      },
      templateimage: {
        title: 'Templat trenger bilde',
        desc: 'Det nye templatet :templatename trenger et visningsbilde',
        btn: 'Utfør oppgave',
        completedBy: 'Fullført av :username'
      },
      // completedtask: {
      //   title: 'En oppgave ble fullført',
      //   desc: ':user utførte oppgaven :taskid'
      // },
      styletypeimage: {
        title: 'Stiltype trenger bilde',
        desc: 'Templatet :templatename trenger et stiltypebilde for stilen :styletypename',
        btn: 'Utfør oppgave',
        completedBy: 'Fullført av :username'
      },
      templatedesignerbg: {
        title: 'Designer trenger bakgrunnsbilde',
        desc: 'Designeren i templatet :templatename trenger et bakgrunnsbilde',
        btn: 'Utfør oppgave',
        completedBy: 'Fullført av :username'
      },
      pills: {
        pending: 'Venter',
        completed: 'Fullført',
        skipped: 'Hoppet over',
        deleted: 'Slettet',
        unknown: '(ukjent)'
      }
    }
  },
  modals: {
    skip: {
      title: 'Hopp over oppgave',
      desc: 'Vil du virkelig hoppe over oppgaven:<br><b>:taskDesc</b>',
      btn: {
        loading: 'Hopper over oppgaven',
        default: 'Hopp over oppgaven'
      },
      toast: {
        success: {
          title: 'Hoppet over oppgaven',
          msg: 'Oppgaven ble fjernet fra oppgavelisten'
        },
        error: {
          title: 'Oppgaven ble ikke hoppet over',
          msg: 'Det oppstod en feil ved fjerning av oppgaven: :error'
        }
      }
    }
  }
}
